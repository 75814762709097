<template>
  <main class="md:ml-12 yippy">
    <!-- Message to show when SW Sync is in progress -->
    <div v-if="isACleanSlate">
      <trac-dual-sync
        :status="dualSyncStatus"
        :progress="progress"
        @onResync="reSync"
      ></trac-dual-sync>
    </div>

    <!-- Normal Loading UI -->
    <div v-else>
      <div class="wrapper" v-if="dataLoaded">
        <div
          class="
            grid
            md:flex
            flex-row
            mr-4
            md:mr-0 md:justify-between
            items-end
            md:mb-6
          "
        >
          <ul class="flex-col mt-12">
            <li><a href="#">Customers</a></li>
            <li class="font-bold mt-4 text-2xl whitespace-no-wrap">
              Total: {{ totalRecords }} customer(s)
            </li>
          </ul>
          <!-- <ul
            class="grid md:flex flex-row mt-4 items-end md:justify-end w-full"
          >
            <li class="md:mr-5">
              <trac-button variant="outline" class="w-full" :disabled="true">
                IMPORT CSV</trac-button
              >
            </li>
            <li class="py-4 md:py-0">
              <trac-button
                @button-clicked="exportCustomers"
                variant="outline"
                class="w-full"
                :disabled="true"
                >EXPORT CSV</trac-button
              >
            </li>
          </ul> -->
        </div>
        <!-- <div class="">
        <p class="text-xs text-gray-500 text-right my-2">
          <span class="text-red-600">*</span>
          Offline customers
        </p>
      </div> -->
        <div
          id="scrollSection"
          class="
            bg-white
            rounded-lg
            overflow-scroll
            big-shadow
            h-screen
            mr-4
            md:mr-0
          "
          @scroll="doDelayedSearch($event)"
        >
          <div class="mt-8 mb-5 mx-8 md:justify-between grid md:flex flex-row">
            <div class="flex flex-row items-center md:w-5/12">
              <div
                :class="{'bg-gray-300': searching, 'bg-white' : !searching}"
                class="
                  flex flex-row
                  items-center
                  rounded
                  big-shadow
                  justify-between
                  px-2
                  w-full
                "
              >
                <span class="text-xs font-light text-primaryGray w-full">
                  <input
                    :disabled="searching"
                    ref="searchBar"
                    class="w-full py-2 px-4 outline-none"
                    type="text"
                    name=""
                    id=""
                    data-test="customer-search-field"
                    placeholder="Search customer..."
                    v-model="searchedData"
                  />
                  <!-- v-model.trim="searchedProduct" -->
                </span>
                <img data-test="customer-search-icon" v-if="!searching" src="../../assets/svg/Search_icon.svg" />
                <div class="text-sm" v-else>{{percentageSearched}}</div>
              </div>
              <!-- <trac-dropdown class="mr-5" :menuItems="menuItems"></trac-dropdown> -->
              <!-- <div class="flex flex-row items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="8.16267"
                  cy="8.16267"
                  r="7.16267"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.116 17.116L13.2213 13.2213"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <label class="ml-3 text-xs font-bold text-primaryBlue"
                >SEARCH</label
              >
            </div> -->
            </div>
            <div>
              <!-- <trac-button @button-clicked="$router.push({ name: 'CustomerLoyalty' })" class="mr-4"
              >CUSTOMER LOYALTY</trac-button
            > -->
              <trac-button
              data-test="add-new-customer-button"
                @button-clicked="gotoCustomersInputPage"
                class="w-full mt-4 md:mt-0"
                >ADD NEW CUSTOMER</trac-button
              >
              <!-- <button @click="reSync()">Refresh</button> -->
            </div>
          </div>
          <div class="mx-8 mb-8 border rounded-lg overflow-scroll">
            <table class="w-full table-auto">
              <thead class="bg-blue-100">
                <tr class="">
                  <th class="text-left text-xs p-4">
                    <trac-checkbox @check="selecteAllCustomers"></trac-checkbox>
                  </th>
                  <th
                    class="
                      text-left text-xs
                      font-semibold
                      whitespace-no-wrap
                      p-4
                    "
                  >
                    CUSTOMER NAME
                  </th>
                  <th
                    class="
                      text-left text-xs
                      font-semibold
                      whitespace-no-wrap
                      p-4
                    "
                  >
                    LOCATION
                  </th>
                  <th
                    class="
                      text-left text-xs
                      font-semibold
                      whitespace-no-wrap
                      p-4
                    "
                  >
                    PHONE NUMBER
                  </th>
                  <th
                    class="
                      text-left text-xs
                      font-semibold
                      whitespace-no-wrap
                      p-4
                    "
                  >
                    EMAIL
                  </th>
                  <!-- <th class="text-left text-xs font-semibold whitespace-no-wrap p-4">BALANCE</th> -->
                </tr>
              </thead>
              <tbody v-if="customers.length > 0">
                <tr
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                  v-for="(customer, index) in customers"
                  class="hover:bg-gray-200 cursor-pointer"
                  :key="index"
                >
                  <td class="text-xs">
                    <trac-checkbox
                    data-test="tick-box"
                      class="p-4"
                      @check="customer.isSelected = !customer.isSelected"
                      :isChecked="customer.isSelected"
                    ></trac-checkbox>
                  </td>
                  <td class="text-xs capitalize">
                    <div class="p-4" @click="openCustomerDetails(customer)">
                      {{ customer.name }}
                      <span
                        class="text-red-900 bg-red-300 p-1 rounded"
                        style="font-size: 0.4rem"
                        v-if="!customer._id"
                        >not synced</span
                      >
                    </div>
                  </td>
                  <td class="text-xs capitalize">
                    <div class="p-4" @click="openCustomerDetails(customer)">
                      {{ customer.location }}
                    </div>
                  </td>
                  <td class="text-xs">
                    <div class="p-4" @click="openCustomerDetails(customer)">
                      {{ customer.phone }}
                    </div>
                  </td>
                  <td class="text-xs lowercase">
                    <div class="p-4" @click="openCustomerDetails(customer)">
                      {{ customer.email }}
                    </div>
                  </td>
                  <!-- <td class="text-xs">
                  <div class="p-4" @click="openCustomerDetails(customer)">
                    {{ customer.balance | formatPrice(false) }}
                  </div>
                </td> -->
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7">
                    <trac-center-data>
                      <div class="h-40 flex items-center text-lg text-gray-600">
                        {{
                          searchedData.length > 0
                            ? customers.length > 0
                              ? "No match found."
                              : "No customer added yet."
                            : "No customer added yet."
                        }}
                      </div>
                    </trac-center-data>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="flex justify-center pb-10 pt-3 items-center w-full">
            <pagination
              class=" mt-4 mx-auto"
              v-if="paginated"
              v-model="page"
              :records="sortedCustomers.length"
              :per-page="10"
            />
          </div> -->
          </div>
        </div>
      </div>
      <div class="w-full h-screen" v-else>
        <trac-loading />
      </div>
    </div>
  </main>
</template>

<script>
const debounce = function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};
import AdvancedSearch from "../../offline-module/advancedSearch";
import Pagination from "vue-pagination-2";
import { cleanSlate } from "../../offline-module/offline.store";
import {
  DELETE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
  SAVE_LOCAL_DB_DATA,
  GET_USER_TOKEN,
} from "../../browser-db-config/localStorage";
import OfflineManager from "../../offline-module";
import { apiUrl } from "../../environment/environment";
import { eventBus } from "./../../main";
import { ModuleConfig } from "../../offline-module/config";

const debounceEvent =
  (callback, time = 250, interval) =>
  (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => callback(...args), time);
  };

export default {
  name: "Customers",
  data() {
    return {
      customers: [],
      showingCustomers: [],
      filteredCustomers: null,
      dataLoaded: true,
      searchedData: "",
      searching: false,
      progress: {
        name: "",
        recordsDone: 0,
        total: 0,
      },
      menuItems: [
        { name: "filter1", value: 1 },
        { name: "filter2", value: 2 },
        { name: "filter3", value: 3 },
        { name: "filter4", value: 4 },
      ],
      page: 0,
      totalRecords: 0,
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
        includeUnsynced: true
      }),
    };
  },
  components: {
    Pagination,
  },
  watch: {
    SWData(newValue, oldValue) {
      console.log("Customer -> SWData, ", newValue);
      if (this.isACompletedDualSyncEvent(newValue, 'customers')) {
        // this.dualSyncStatus = "syncing";
        console.log('DualSync Detected: ', newValue)
        cleanSlate.moduleList["customers"] = false;
        this.loadOfflineRecords();
      }

      if (this.isAProgressSyncEvent(newValue, 'customers')) {
        this.progress = newValue.data;
      }
    },
    searchedData: debounce(function (newVal) {
      console.log('debouned please: ', newVal)
      this.filterBySearch(newVal)
    }, 1000)
  },
  async created() {
    await this.NetworkCheck();
    this.dualSyncStatus = "syncing";
    this.loadOfflineRecords();
  },
  beforeRouteLeave(to, from, next) {
    this.customers = [];
    this.showingCustomers = [];
    // this.offlineManager = null;
    // console.log('customers array before leaving: ', this.offlineManager)
    next();
  },
  beforeDestroy() {
    console.log("beforeDestroy Hook Called", this.customers);
  },
  computed: {
    isACleanSlate() {
      return cleanSlate.moduleList["customers"] === true;
    },
    percentageSearched() {
      return `${Math.floor((this.adv.currentIndex / this.adv.numberOfPages) * 100)}%`
    },
    // paginated() {
    //   if (this.sortedCustomers.length > 100) {
    //     let start = parseInt((this.page - 1) * 10);
    //     let end = parseInt(this.page * 10);
    //     return this.sortedCustomers.slice(start, end);
    //   } else {
    //     return this.sortedCustomers;
    //   }
    // },
    sortedCustomers() {
      console.log(this.filteredCustomers);
      return this.filteredCustomers.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    readyForExport() {
      return this.filteredCustomers.find((customer) => customer.isSelected);
    },
  },
  methods: {
    reSync(data) {
      console.log("resync function: ", data);
      // For single request
      if (this.isOnline) {
        this.resetDualSyncDialog();
        this.requestSync("customers");
      } else {
        this.resetDualSyncDialog();
        this.loadOfflineRecords();
      }
    },
    async loadOfflineRecords() {
      console.log("loadOfflineRecords() called");
      this.totalRecords = await this.adv.getTotalRecords("customers")
      const results = await this.adv.paginateRecords({
        tableName: "customers",
        pageIndex: 0, // <-- this.page can change outside of this fxn, so we set this 0 in this function
        loopThrough: true,
        resultsPerSearch: 30, // <-- first set must be at least 30 for checkScroll() to work
      })
      const customerRemapped = this.remapCustomers(results);
      this.showingCustomers = customerRemapped
      this.filteredCustomers = customerRemapped
      this.customers = customerRemapped
      this.dataLoaded = true;
      // debugger

      // this.adv.getTotalRecords("customers").then((total) => {
      //   this.totalRecords = total;
      // });

      // here, we set loopThrough to 'true', so go through as many
      // pages as needed to get at least 30 records.
      // So that checkScroll can be fired when you scroll to the bottom of
      // results
      // this.adv
      //   .paginateRecords({
      //     tableName: "customers",
      //     pageIndex: this.page,
      //     loopThrough: true,
      //     resultsPerSearch: 30, // <-- first set must be at least 30 for checkScroll() to work
      //   })
      //   .then((fetched) => {
      //     const customerRemapped = this.remapCustomers(fetched);
      //     this.page = this.adv.currentIndex;
      //     debugger;
      //     this.customers = customerRemapped;
      //     this.filteredCustomers = customerRemapped;
      //     this.showingCustomers = customerRemapped;
      //     this.dataLoaded = true;
      //     // this.dualSyncStatus = false;
      //   });

      // this.$GlobalOfflineManager.getSyncedAndUnSyncedRecords("customers").then((all_records) => {
      //   const customerRemapped = this.remapCustomers(all_records);
      //   this.customers = customerRemapped;
      //   this.filteredCustomers = customerRemapped;

      //   // Setting customers to show an initial amount
      //   this.showingCustomers = this.sortedCustomers.slice(0, 30);

      //   this.dataLoaded = true;
      //   this.dualSyncStatus = false;
      // }).catch(err => location.reload())
    },
    remapCustomers(customerArray = []) {
      return customerArray.map((customer) => {
        return {
          _id: customer._id,
          offline_cust_id: customer.offline_cust_id || false,
          is_offline_data: customer.is_offline_data || false,
          day_month: customer.day_month || "",
          notes: customer.notes,
          is_offline_data: customer.is_offline_data,
          first_name: customer.first_name || "",
          last_name: customer.last_name || "",
          name: (customer.first_name || "") + " " + (customer.last_name || ""),
          first_name: customer.first_name,
          last_name: customer.last_name,
          location:
            customer.address.city || "" + " " + customer.address.state
              ? customer.address.state
              : "",
          address: customer.address,
          birthday: customer.birthday,
          phone: customer.phone,
          email: customer.email,
          balance: 0,
          isSelected: false,
        };
      });
    },
    doSync() {
      this.$SendMessageToSW({
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "customers_unsynced",
          tableToSync: "customers",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: `${apiUrl}/v1/customers/syncnewofflinecustomers`,
          moduleGETAPIEndpoint: "",
          offlineKeyValue: "offline_cust_id",
        },
        EVENT_TYPE: "SYNC",
      });
    },
    // doBackgroundSync(lastUpdate = 0) {
    //   this.$SendMessageToSW({
    //     EVENT_PAYLOAD: {
    //       BusinessId: GET_USER_BUSINESS_ID(),
    //       nonSyncedTable: "customers_unsynced",
    //       tableToSync: "customers",
    //       authToken: GET_USER_TOKEN(),
    //       moduleAPIEndpoint: `${apiUrl}/v1/customers/syncnewofflinecustomers`,
    //       moduleGETAPIEndpoint: `${apiUrl}/v2/customers/businesses/${GET_USER_BUSINESS_ID()}${
    //         lastUpdate ? "?updated_at=" + lastUpdate : ""
    //       }`,
    //       offlineKeyValue: "offline_cust_id",
    //     },
    //     EVENT_TYPE: "BACKGROUND_SYNC",
    //   });
    // },
    async filterBySearch(searchTerm) {
      this.searching = true
      // since this was called, the reset the page to 0
      this.page = 0;
      // console.log(this.showingCustomers);

      const results = await this.doKeywordSearch(searchTerm);
      // debugger
      this.customers = this.remapCustomers(results);

      this.$refs.searchBar.focus()

      this.searching = false
      // this.adv.searchAccrossRecordFields({
      //   tableName: 'customers',
      //   searchTerm: this.searchedData,
      //   recordFields: ["first_name", "last_name", "phone", "email"],
      //   pageIndex: this.page
      // }).then((searchResults) => {
      //   this.showingCustomers = searchResults
      //   // this.searching = false;
      //   // this.results = searchResults;
      // });

      // this.filteredCustomers =
      //   await this.$GlobalOfflineManager.searchAccrossRecordFields(
      //     "customers",
      //     this.searchedData,
      //     ["first_name", "last_name", "phone", "email"]
      //   );

      // console.log("Searched Result", this.filteredCustomers);

      // this.filteredCustomers = this.filteredCustomers.map((customer) => {
      //   return {
      //     ...customer,
      //     name: (customer.first_name || "") + " " + (customer.last_name || ""),
      //   };
      // });

      // this.showingCustomers = this.sortedCustomers.slice(0, 30);

      // console.log(
      //   "Search term is ",
      //   this.searchedData,
      //   "results: ",
      //   this.filteredCustomers
      // );
      // this.showingCustomers = this.filteredCustomers.slice(0, 30).sort((a,b) => a.name > b.name ? 1 : -1);
    },
    async doKeywordSearch(searchTerm = "", doLoopThrough = true) {
      const searchCriteria = {
        tableName: "customers",
        searchTerm: searchTerm,
        recordFields: ["first_name", "last_name", "phone", "email"],
        pageIndex: this.page,
        loopThrough: doLoopThrough,
        resultsPerSearch: 30,
      }
      // debugger
      const results = await this.adv.searchAccrossRecordFields(searchCriteria);
      this.page = this.adv.currentIndex;
      this.adv.resetOfflineKeyTracking()
      return results;
    },
    doDelayedSearch(e) {
      debounceEvent(this.checkScroll(e), 250);
    },
    async checkScroll(e) {
      const myDiv = e.target;
      const shownCustomersSofar = this.showingCustomers;
      // debugger
      console.log("scroll hook");

      if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight) {
        console.log("scroll reached threshold. this.page is", this.page);
        const searchedWord = this.searchTerm;
        // debugger

        if (searchedWord) {
          if (this.page < this.adv.numberOfPages - 1) {
            this.page++;
            const nextResults = await this.doKeywordSearch(searchedWord, false);
            this.customers = [].concat(
              this.customers,
              this.remapCustomers(nextResults)
            );
          }
        } else {
          // this.customers = []
          const continuePaginating = this.adv.tooFewToLoopThrough === false &&
            this.customers.length < this.totalRecords &&
            this.page < this.adv.numberOfPages - 1;
          // debugger
          if (continuePaginating) {
            const results = await this.adv.paginateRecords({
              tableName: "customers",
              pageIndex: this.page,
              loopThrough: false, // will only load next set of results at a time
              resultsPerSearch: 30,
            });
            // this.customers.concat(results)
            this.customers = [].concat(
              this.customers,
              this.remapCustomers(results)
            );
            this.page++;
          }
        }

        // debugger

        // If a search word was already in text box, then search
        // according that word
        // if (this.searchTerm) {
        //   this.doKeywordSearch(this.searchTerm).then((results) => {
        //     if (this.page <= 10) {
        //       this.showingCustomers.concat(results)
        //       this.page++
        //     }
        //   })
        // } else {
        //   // if not, then load all data on a batch by batch basis
        //   this.showingCustomers = []
        //   const results = await this.adv.paginateRecords({
        //     tableName: 'customers',
        //     pageIndex: this.page
        //   })
        //   // debugger
        // }
        // this.page++
        // this.filterBySearch
        // if (shownCustomersSofar.length === this.customers.length) {
        // } else {

        //   this.showingCustomers.push(
        //     ...this.sortedCustomers.slice(
        //       shownCustomersSofar.length,
        //       shownCustomersSofar.length + 10
        //     )
        //   );
        // }
      }
    },
    // filterBySearch(e) {
    //   const value = e.target.value;
    //   this.searchedData = value;
    //   // Removed a condition
    //   if (1) {
    //     // (value);
    //     this.filteredCustomers = this.customers.filter((customer) => {
    //       return (
    //         customer.name.toLowerCase().includes(value.toLowerCase().trim()) ||
    //         (customer.email || "")
    //           .toLowerCase()
    //           .includes(value.toLowerCase().trim()) ||
    //         (customer.phone || "").includes(value.trim())
    //       );
    //     });

    //     this.showingCustomers = this.filteredCustomers.slice(0, 30);
    //     // console.log(this.showingCustomers);
    //   }
    // },
    gotoCustomersInputPage() {
      this.$router.push({ name: "AddCustomer" });
    },
    openCustomerDetails(customer) {
      SAVE_LOCAL_DB_DATA("customer-details", customer);
      this.$router.push({ name: "CustomerDetails" });
    },
    selecteAllCustomers(e) {
      this.customers.forEach((customer) => (customer.isSelected = e.checked));
    },
    exportCustomers() {
      {
        let csv = "Firstname,Lastname,Email,Phone,Street,City,State,Country\n";
        const listToexport = this.customers.filter(
          (customer) => customer.isSelected
        );

        listToexport.forEach((customer) => {
          csv += `${customer.first_name},${customer.last_name},${
            customer.email
          },${customer.phone},${customer.address.street || ""},${
            customer.address.city || ""
          },${customer.address.state || ""},${
            customer.address.country || ""
          }\n`;
        });

        const hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = "traction_customers.csv";
        hiddenElement.click();
      }
    },
    async fetchAllCustomers() {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS_VIA_OFFLINE_MGR");
      // await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const res = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (1) {
        const customersData = res.data ? res.data.items || [] : [];
        this.customers = customersData.map((customer) => {
          return {
            _id: customer._id,
            offline_cust_id: customer.offline_cust_id || false,
            is_offline_data: customer.is_offline_data || false,
            day_month: customer.day_month || "",
            notes: customer.notes,
            is_offline_data: customer.is_offline_data,
            first_name: customer.first_name || "",
            last_name: customer.last_name || "",
            name:
              (customer.first_name || "") + " " + (customer.last_name || ""),
            first_name: customer.first_name,
            last_name: customer.last_name,
            location:
              customer.address.city || "" + " " + customer.address.state
                ? customer.address.state
                : "",
            address: customer.address,
            birthday: customer.birthday,
            phone: customer.phone,
            email: customer.email,
            balance: 0,
            isSelected: false,
          };
        });

        this.filteredCustomers = this.customers;
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }

      // console.log(this.customers.length);

      // await this.collateCustomerBalance();
    },
    async collateCustomerBalance() {
      await this.$store.dispatch("FETCH_ALL_INVOICES");
      let invoices = this.$store.getters["GET_ALL_INVOICES"].data || [];
      invoices = invoices.invoice ? invoices.invoice : [];

      this.customers.forEach((customer) => {
        customer.balance = 0;
        // Loop through all invoices in the business
        invoices.forEach((invoice) => {
          if (customer._id === invoice.client) {
            customer.balance +=
              isNaN(+invoice.balance.outstanding.amount) ||
              +invoice.balance.outstanding.amount === undefined ||
              +invoice.balance.outstanding.amount === null
                ? 0
                : +invoice.balance.outstanding.amount;
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
